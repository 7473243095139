import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ExternalResource } from '@mona/models';
import { ExternalResourcesState } from '../entities';
import { ExternalResourcesActions, ExternalResourcesSelectors } from '../state';

/**
 * Notifications store service
 */
@Injectable({ providedIn: 'root' })
export class DataAccessExternalResourcesFacade {
    /** Get daily goals map */
    readonly externalResources$: Observable<ExternalResource[]> = this.store.select(
        ExternalResourcesSelectors.selectExternalResourcesData,
    );

    /** Get selected external resource */
    readonly selectedExternalResource$: Observable<ExternalResource | null> = this.store.select(
        ExternalResourcesSelectors.selectSelectedExternalResource,
    );

    /** is daily goals data loading */
    readonly isLoading$ = this.store.select(ExternalResourcesSelectors.selectExternalResourcesLoading);

    /**
     * Constructor
     *
     * @param store store
     */
    constructor(private store: Store<ExternalResourcesState>) {}

    /**
     * get external resources
     */
    public getExternalResources(): void {
        this.store.dispatch(ExternalResourcesActions.loadExternalResources());
    }

    /**
     * clear external resources
     */
    public clearExternalResources(): void {
        this.store.dispatch(ExternalResourcesActions.clearExternalResources());
    }

    /**
     * select external resource
     * @param selected
     */
    public selectExternalResource(selected: ExternalResource): void {
        this.store.dispatch(ExternalResourcesActions.selectExternalResource({ selected }));
    }

    /**
     * clear selected external resource
     */
    public clearSelectedExternalResource(): void {
        this.store.dispatch(ExternalResourcesActions.clearSelectExternalResource());
    }

    /**
     * browser navigate back
     */
    public browserNavigateBack(): void {
        this.store.dispatch(ExternalResourcesActions.browserNavigateBackAction());
    }

    /**
     * browser navigate forward
     */
    public browserNavigateForward(): void {
        this.store.dispatch(ExternalResourcesActions.browserNavigateForwardAction());
    }

    /**
     * browser reload
     */
    public browserReload(): void {
        this.store.dispatch(ExternalResourcesActions.browserReloadAction());
    }

    /**
     * browser end session
     */
    public browserEndSession(): void {
        this.store.dispatch(ExternalResourcesActions.browserEndSessionAction());
    }

    /**
     * browser maximize window
     * @param payload
     */
    public browserViewResize(payload): void {
        this.store.dispatch(ExternalResourcesActions.browserViewResize(payload));
    }

    /**
     * browser set window shown
     */
    public browserSetWindowShown(): void {
        this.store.dispatch(ExternalResourcesActions.browserSetWindowShownAction());
    }

    /**
     * browser set window hidden
     */
    public browserSetWindowHidden(): void {
        this.store.dispatch(ExternalResourcesActions.browserSetWindowHiddenAction());
    }
}
