<form
    *ngIf="formGroup && !isCardAppearance"
    [formGroup]="formGroup"
    [shouldEmitBlurOnActiveFieldChange]="true"
    (keydown.enter)="keyboardEnterClicked.emit()"
    uiDisableWhen
    checkPermission="patient_overview_edit"
>
    <div class="app-personal-data-form__id-card" *ngIf="isPatientFromSearch">
        <div class="app-personal-data-form__id-card__item" data-testid="patientNumberValue">
            <span class="app-personal-data-form__id-card__item__label">
                {{ 'apps.admission.personalData.patientNumber' | translate }}:
            </span>
            <span>&nbsp;{{ formGroup.controls.patientNumber.value }}</span>
        </div>
        <div class="app-personal-data-form__id-card__item" data-testid="hospitalCaseIdValue">
            <span class="app-personal-data-form__id-card__item__label">
                {{ 'apps.admission.personalData.hospitalCaseId' | translate }}:
            </span>
            <span>&nbsp;{{ formGroup.controls.caseId?.value }}</span>
        </div>
    </div>
    <div class="app-personal-data-form__row app-personal-data-form__row--first" *ngIf="!isPatientFromSearch">
        <mat-form-field
            class="app-input-field app-personal-data-form__number"
            appearance="fill"
            data-testid="ff-patientNumber"
        >
            <mat-label>{{ 'apps.admission.personalData.patientNumber' | translate }}</mat-label>
            <input
                [formControl]="formGroup.controls.patientNumber"
                matInput
                required
                placeholder="{{ 'apps.admission.personalData.patientNumber' | translate }}"
                data-testid="uiPatientNumberInput"
            />
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.patientNumber"></ui-validation-message>
            </mat-error>
        </mat-form-field>
        <mat-form-field
            class="app-input-field app-personal-data-form__number"
            appearance="fill"
            data-testid="ff-caseId"
        >
            <mat-label>{{ 'apps.admission.personalData.hospitalCaseId' | translate }}</mat-label>
            <input
                [formControl]="formGroup.controls.hospitalCaseId"
                disabled
                matInput
                maxlength="15"
                placeholder="{{ 'apps.admission.personalData.caseId' | translate }}"
                data-testid="uiPatientCaseIdInput"
            />
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.caseId"></ui-validation-message>
            </mat-error>
        </mat-form-field>
        <ng-container *ngIf="!formGroup.controls.weight || !formGroup.controls.height">
            <ng-container *ngTemplateOutlet="birthdayInput"></ng-container>
        </ng-container>
    </div>
    <div class="app-personal-data-form__row">
        <mat-form-field
            class="app-input-field app-personal-data-form__title"
            [class.app-changed-input]="'prefix' | isPatientFieldChanged: stagedChanges"
            appearance="fill"
            data-testid="ff-patientTitle"
        >
            <mat-label>{{ 'apps.admission.personalData.patientTitle' | translate }}</mat-label>
            <mat-select
                [formControl]="formGroup.controls.title"
                panelClass="app-input-select-panel scrollbar-visible"
                data-testid="uiPatientTitleSelect"
            >
                <mat-option [attr.data-testid]="'uiPatientTitleSelect_' + 'patientTitleNone'">
                    {{ 'apps.admission.personalData.patientTitleNone' | translate }}
                </mat-option>
                <mat-option [value]="patientTitle.Dr" [attr.data-testid]="'uiPatientTitleSelect_' + patientTitle.Dr">
                    {{ 'apps.admission.personalData.patientTitleDr' | translate }}
                </mat-option>
                <mat-option
                    [value]="patientTitle.Prof"
                    [attr.data-testid]="'uiPatientTitleSelect_' + patientTitle.Prof"
                >
                    {{ 'apps.admission.personalData.patientTitleProf' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field
            class="app-input-field app-personal-data-form__name"
            [class.app-changed-input]="'firstName' | isPatientFieldChanged: stagedChanges"
            appearance="fill"
            data-testid="ff-patientFirstName"
        >
            <mat-label>{{ 'apps.admission.personalData.firstName' | translate }}</mat-label>
            <input
                [formControl]="formGroup.controls.firstName"
                matInput
                required
                placeholder="{{ 'apps.admission.personalData.firstName' | translate }}"
                data-testid="ff-patientFirstNameInput"
            />
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.firstName"></ui-validation-message>
            </mat-error>
        </mat-form-field>
        <mat-form-field
            class="app-input-field app-personal-data-form__name"
            [class.app-changed-input]="'lastName' | isPatientFieldChanged: stagedChanges"
            appearance="fill"
            data-testid="ff-patientLastName"
        >
            <mat-label>{{ 'apps.admission.personalData.lastName' | translate }}</mat-label>
            <input
                [formControl]="formGroup.controls.lastName"
                matInput
                required
                placeholder="{{ 'apps.admission.personalData.lastName' | translate }}"
                data-testid="ff-patientLastNameInput"
            />
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.lastName"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="app-personal-data-form__row">
        <mat-form-field
            class="app-input-field app-personal-data-form__sex"
            [class.app-changed-input]="'gender' | isPatientFieldChanged: stagedChanges"
            appearance="fill"
            data-testid="ff-patientBioSex"
        >
            <mat-label>{{ 'apps.admission.personalData.sex' | translate }}</mat-label>
            <mat-select
                [formControl]="formGroup.controls.gender"
                required
                panelClass="app-input-select-panel scrollbar-visible"
                data-testid="ff-patientBioSexSelect"
            >
                <mat-option [value]="patientSex.Male" [attr.data-testid]="'uiPatientBioSexSelect_' + patientSex.Male">
                    {{ 'apps.admission.personalData.sexMale' | translate }}
                </mat-option>
                <mat-option
                    [value]="patientSex.Female"
                    [attr.data-testid]="'uiPatientBioSexSelect_' + patientSex.Female"
                >
                    {{ 'apps.admission.personalData.sexFemale' | translate }}
                </mat-option>
            </mat-select>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.gender"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="app-personal-data-form__row">
        <ng-container *ngTemplateOutlet="birthdayInput"></ng-container>
        <mat-form-field
            class="app-input-field app-personal-data-form__height"
            appearance="fill"
            data-testid="ff-patientHeight"
        >
            <mat-label>{{ 'apps.admission.personalData.height.label' | translate }}</mat-label>
            <input
                [formControl]="formGroup.controls.height"
                matInput
                required
                type="number"
                matInputCommaDot
                data-type="number"
                placeholder="{{ 'apps.admission.personalData.height.placeholder' | translate }}"
                data-testid="ff-patientHeightSelect"
            />
            <span class="app-input-field__suffix app-input-field__suffix--dynamic" matSuffix>
                {{ 'units.centiMeterShortcut' | translate }}
            </span>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.height"></ui-validation-message>
            </mat-error>
        </mat-form-field>
        <mat-form-field
            class="app-input-field app-personal-data-form__weight"
            appearance="fill"
            data-testid="ff-patientWeight"
        >
            <mat-label>{{ 'apps.admission.personalData.weight.label' | translate }}</mat-label>
            <input
                [formControl]="formGroup.controls.weight"
                matInput
                required
                matInputCommaDot
                data-type="number"
                type="number"
                placeholder="{{ 'apps.admission.personalData.weight.placeholder' | translate }}"
                data-testid="ff-patientHeightInput"
            />
            <span class="app-input-field__suffix app-input-field__suffix--dynamic" matSuffix>
                {{ 'units.kiloGramShortcut' | translate }}
            </span>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.weight"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="app-personal-data-form__row">
        <mat-form-field
            class="app-input-field app-personal-data-form__admission"
            appearance="fill"
            data-testid="ff-admissionDiagnosis"
        >
            <mat-label>{{ 'apps.admission.admissionDiagnosis.title' | translate }}</mat-label>
            <input
                [formControl]="formGroup.controls.admissionDiagnosis"
                matInput
                placeholder="{{ 'apps.admission.admissionDiagnosis.title' | translate }}"
                data-testid="uiAdmissionDiagnosisIdInput"
            />
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.admissionDiagnosis"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>

    <ng-content></ng-content>
</form>

<div
    *ngIf="isCardAppearance"
    (click)="formGroup.enabled && handleFormClick($event)"
    class="app-personal-data-form__review-card"
>
    <div class="app-personal-data-form__review-card__column">
        <div class="app-personal-data-form__review-card__item">
            <span class="app-personal-data-form__review-card__item__label">
                {{ 'apps.admission.personalData.patientNumber' | translate }}
            </span>
            <span>{{ formGroup.controls.patientNumber.value }}</span>
        </div>
        <div class="app-personal-data-form__review-card__item">
            <span class="app-personal-data-form__review-card__item__label">
                {{ 'apps.admission.personalData.hospitalCaseId' | translate }}
            </span>
            <span>{{ formGroup.controls.hospitalCaseId?.value }}</span>
        </div>
        <div class="app-personal-data-form__review-card__item" *ngIf="staysList?.length">
            <span class="app-personal-data-form__review-card__item__label">
                {{ 'apps.admission.personalData.pastStays' | translate }}
            </span>
            <span class="stays-list">
                <a href="undefined" class="stays-list-link" *ngFor="let stay of staysList">
                    {{ stay.startDate | date: 'dd.MM.yy' }} - {{ stay.endDate | date: 'dd.MM.yy' }}
                    <mat-icon (click)="handleStayClick($event, stay)">open_in_new</mat-icon>
                </a>
            </span>
        </div>

        <div class="app-personal-data-form__review-card__item">
            <span class="app-personal-data-form__review-card__item__label">
                {{ 'apps.admission.admissionDiagnosis.title' | translate }}
            </span>
            <span>{{ formGroup.controls.admissionDiagnosis?.value }}</span>
        </div>
    </div>
    <div class="app-personal-data-form__review-card__column">
        <div class="app-personal-data-form__review-card__item">
            <span class="app-personal-data-form__review-card__item__label">
                {{ 'apps.admission.personalData.name' | translate }}
            </span>
            <span>{{ formGroup.controls.firstName.value + ' ' + formGroup.controls.lastName.value }}</span>
        </div>
        <div class="app-personal-data-form__review-card__item">
            <span class="app-personal-data-form__review-card__item__label">
                {{ 'apps.admission.personalData.sex' | translate }}
            </span>
            <span>{{ 'apps.admission.personalData.gender.' + formGroup.controls.gender?.value | translate }}</span>
        </div>
        <div class="app-personal-data-form__review-card__item">
            <span class="app-personal-data-form__review-card__item__label">
                {{ 'apps.admission.personalData.dateOfBirth' | translate }}
            </span>
            <span>{{ formGroup.controls.dateOfBirth?.value | date: 'dd.MM.yyyy' }}</span>
        </div>
        <div class="app-personal-data-form__review-card__item">
            <span class="app-personal-data-form__review-card__item__label">
                {{ 'apps.admission.personalData.height.label' | translate }}
            </span>
            <span>{{ formGroup.controls.height?.value }} {{ 'units.centiMeterShortcut' | translate }}</span>
        </div>
        <div class="app-personal-data-form__review-card__item">
            <span class="app-personal-data-form__review-card__item__label">
                {{ 'apps.admission.personalData.weight.label' | translate }}
            </span>
            <span>{{ formGroup.controls.weight?.value }} {{ 'units.kiloGramShortcut' | translate }}</span>
        </div>
        <div class="app-personal-data-form__review-card__item">
            <span class="app-personal-data-form__review-card__item__label">
                {{ 'apps.admission.personalData.bmi' | translate }}
            </span>
            <span>{{ bmi }}</span>
        </div>
    </div>
</div>

<ng-template #birthdayInput>
    <mat-form-field
        class="app-input-field app-personal-data-form__birthday"
        [class.app-changed-input]="'dateOfBirth' | isPatientFieldChanged: stagedChanges"
        appearance="fill"
        data-testid="ff-patientDateOfBirth"
    >
        <mat-label>{{ 'apps.admission.personalData.dateOfBirth' | translate }}</mat-label>
        <input
            matInput
            required
            readonly
            (click)="picker.open()"
            [max]="currentDate"
            [formControl]="formGroup.controls.dateOfBirth"
            [matDatepicker]="picker"
            data-testid="ff-patientDateOfBirthInput"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [touchUi]="isBedSideMode"></mat-datepicker>
        <mat-error>
            <ui-validation-message [control]="formGroup.controls.dateOfBirth"></ui-validation-message>
        </mat-error>
    </mat-form-field>
</ng-template>
