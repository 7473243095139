export const WEB_BROWSER_DEFAULT_POSITION_PARAMS = {
    x: 510,
    y: 153,
    width: 1346,
    height: 865,
};

export const WEB_BROWSER_FULLSCREEN_POSITION_PARAMS = {
    x: 98,
    y: 41,
    width: 1822,
    height: 942,
};
