/* eslint-disable  no-empty, jsdoc/require-jsdoc, @angular-eslint/no-empty-lifecycle-method */
import { ChangeDetectorRef, ElementRef, inject, Injectable, InjectFlags } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormControlDirective,
    FormControlName,
    FormGroup,
    NgControl,
    NgModel,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Constructor } from 'type-fest';
import { compareDeepEqual, conditionalStartWith, noop, TakeUntilDestroy, takeUntilDestroy } from '@mona/shared/utils';
import { syncOuterAndInnerDirty, syncOuterAndInnerTouched, syncOuterToInnerErrors } from '../helpers';
import { CVA } from '../models';

/**
 * Mixin to augment a component with ControlValueAccesso support.
 *
 * Remove the `NG_VALUE_ACCESSOR` provider - we instead set the value accessor directly with `NgControl`
 *
 * @param base
 * @param ignoreControl
 */
export function mixinControlValueAccessor<T extends Constructor<{ cdRef: ChangeDetectorRef }>>(
    base: T,
    ignoreControl = false,
): Constructor<CVA> & T {
    @Injectable()
    class WrappedControlValueAccessor extends TakeUntilDestroy(base) {
        stateChanges = new Subject<void>();

        private _valueChanges = new Subject<any>();

        valueChanges: Observable<any> = this._valueChanges.asObservable();

        private validator: ValidatorFn | undefined;
        /**
         * A reference to the outer control. Use ngControl.control inside of the template
         * if you want to simply forward the outer control.
         */
        ngControl: FormControlDirective | FormControlName | NgModel;
        /**
         * Stream of values that are either set on the outer control or set via the value property
         */
        private incomingValues$ = new ReplaySubject<any>(1);

        /** Tracks internal control value (view-model). */
        viewModel?: AbstractControl | FormGroup | FormArray<any>;

        readonly elRef: ElementRef<HTMLElement>;

        get hostElm() {
            return this.elRef?.nativeElement;
        }

        protected _value: unknown;

        get value(): unknown {
            return this._value;
        }
        set value(v: unknown) {
            // if (isNullOrUndefined(v)) { this._value = v; return; }
            if (compareDeepEqual(this.value, v)) {
                return;
            }
            this._value = v;
            this.onChange(v);
            this.cdRef.markForCheck();
            this._valueChanges.next(v);
            this.stateChanges.next();
        }

        constructor(...args: any[]) {
            super(...args);
            if (ignoreControl) {
                return;
            }
            this.elRef = inject<ElementRef<HTMLElement>>(ElementRef);
            this.provideValueAccessor();

            void Promise.resolve().then(() => {
                if (this.ngControl?.control) {
                    this.ngControl.control.valueChanges
                        .pipe(
                            conditionalStartWith(
                                () => !(this.ngControl instanceof NgModel),
                                () => this.ngControl.control.value,
                            ),
                            filter(val => val !== this._value),
                            tap(this.incomingValues$),
                            takeUntilDestroy(this),
                        )
                        .subscribe();
                }
                // this.subscribeTo(this.outerToInner$);
                // this.subscribeTo(this.innerToOuter$);
                this.setupValidator();
                this.syncOuterAndInnerControls();
            });
        }

        // eslint-disable-next-line @angular-eslint/contextual-lifecycle
        ngOnInit() {
            // try to init the inner control
        }

        ngOnDestroy() {
            this.stateChanges.complete();
            if (this.validator && this.ngControl) {
                this.ngControl.control.removeValidators(this.validator);
            }
            // this.focusMonitor.stopMonitoring(this.hostElm);
        }

        writeValue(value: any): void {
            this.value = value;
            this.cdRef.markForCheck();
        }

        registerOnChange(fn: any): void {
            this.onChange = fn;
        }

        registerOnTouched(fn: any): void {
            this.onTouched = fn;
        }

        onChange = (_: any) => noop;
        onTouched = () => noop;

        /** Implement this method in the consuming component for built-in validation */
        validate?(control: AbstractControl): ValidationErrors | null;

        setDisabledState?(isDisabled: boolean): void;

        /**
         * Sets the instance of this component as the valueAccessor. Since this is
         * done here, there's no need to do that on the component that extends this class.
         *
         * Helps to avoid running into a circular import.
         *
         * https://github.com/angular/components/blob/master/guides/creating-a-custom-form-field-control.md#ngcontrol
         */
        private provideValueAccessor() {
            let ngControl;
            try {
                // const ngControl = inject(NgControl, { optional: true, self: true });
                ngControl = inject(NgControl, InjectFlags.Self);
            } catch (e) {}

            if (
                ngControl != null &&
                (ngControl instanceof FormControlDirective ||
                    ngControl instanceof FormControlName ||
                    ngControl instanceof NgModel)
            ) {
                this.ngControl = ngControl;
                this.ngControl.valueAccessor = this;
            }
        }

        private setupValidator() {
            if (this.validate && this.ngControl) {
                this.validator = this.validate.bind(this);
                this.ngControl.control.addValidators(this.validator);
                this.ngControl.control.updateValueAndValidity({
                    emitEvent: false,
                    onlySelf: true,
                });
            }
        }

        /**
         * Syncs touched and dirty states between inner controls and the forwarded ngControl
         * innerControls controls that need to be synced
         */
        private syncOuterAndInnerControls() {
            // The ngControl.control and ngControl.statusChanges used by
            // the following methods are resolved on the next tick. So this function is
            // called from withing the Promise.resolve().
            if (this.ngControl && this.viewModel) {
                syncOuterToInnerErrors(this);
                syncOuterAndInnerTouched(this);
                syncOuterAndInnerDirty(this);
            }
        }
    }

    return WrappedControlValueAccessor;
}
