import { Action, ActionReducerMap, combineReducers, createReducer, MetaReducer, on } from '@ngrx/store';
import { makeAsyncActionReducer, makeEntitiesReducer } from '@mona/store';
import { TerminologyState } from '../../entities';
import { setTerminologyLoadedSuccess, TerminologyActions } from '../actions/terminologyActions';

export const terminologyFeatureKey = 'terminology';

/**
 * Terminology reducers
 */
export const terminologyReducerMap: ActionReducerMap<TerminologyState> = {
    searchDiagnosisAction: makeAsyncActionReducer(TerminologyActions.searchDiagnosisAction),
    vitalSignTypes: makeEntitiesReducer(TerminologyActions.loadVitalSignTypesAction),
    labValueTypes: makeEntitiesReducer(TerminologyActions.loadLabValueTypesAction),
    searchLabValueTypesAction: makeAsyncActionReducer(TerminologyActions.searchLabValueTypesAction),
    ventilationParameterTypes: makeEntitiesReducer(TerminologyActions.loadVentilationParameterTypesAction),
    ventilationModes: makeEntitiesReducer(TerminologyActions.loadVentilationModesAction),
    procedureCategories: makeEntitiesReducer(TerminologyActions.loadProcedureCategoriesAction),
    prescriptionFrequencies: makeEntitiesReducer(TerminologyActions.loadPrescriptionFrequenciesAction),
    medications: makeEntitiesReducer(TerminologyActions.loadMedicationsAction, true),
    medicationCategories: makeEntitiesReducer(TerminologyActions.loadMedicationCategoriesAction),
    medicationGroups: makeEntitiesReducer(TerminologyActions.loadMedicationGroupsAction),
    medicationAdministrationMethods: makeEntitiesReducer(TerminologyActions.loadMedicationAdministrationMethodsAction),
    medicationUnits: makeEntitiesReducer<any>(TerminologyActions.loadMedicationUnitsAction),
    medicationSolutions: makeEntitiesReducer<any>(TerminologyActions.loadMedicationSolutionsAction),
    searchMedicationsAction: makeAsyncActionReducer(TerminologyActions.searchMedicationsAction),
    createMedicationAction: makeAsyncActionReducer(TerminologyActions.createMedicationAction),
    outputFactors: makeEntitiesReducer(TerminologyActions.loadOutputFactorsAction),
    prescriptionFrequencyTimes: makeEntitiesReducer(TerminologyActions.loadPrescriptionFrequencyTimesAction),
    basicCareProcedureTypes: makeEntitiesReducer(TerminologyActions.loadBasicCareProcedureTypesAction),
    practitionerShifts: makeEntitiesReducer(TerminologyActions.loadPractitionerShiftsAction),
    careCheckTypes: makeEntitiesReducer(TerminologyActions.loadCareCheckTypesAction),
    bloodAdministrationTypes: makeEntitiesReducer(TerminologyActions.loadBloodAdministrationTypesAction),
    dischargeReasons: makeEntitiesReducer(TerminologyActions.loadDischargeReasonsAction),
    dosageForms: makeEntitiesReducer(TerminologyActions.loadDosageFormsAction),
    dailyGoalsTerminologyValues: createReducer(
        null,
        on(TerminologyActions.loadDailyGoalsTerminologyAction.succeededAction, (state, action) => action.payload),
    ),
    prescriptionNotGivenReason: makeEntitiesReducer(TerminologyActions.loadPrescriptionNotGivenReasonAction),
    glasgowComaScaleValues: createReducer(
        null,
        on(TerminologyActions.loadGlasgowComaScaleAction.succeededAction, (state, action) => action.payload),
    ),
    taskListShiftFilters: createReducer(
        null,
        on(TerminologyActions.loadTaskListShiftFiltersAction.succeededAction, (state, action) => action.payload),
    ),
    lastUpdatedAt: createReducer(
        null,
        on(
            TerminologyActions.searchDiagnosisAction.succeededAction,
            TerminologyActions.loadVitalSignTypesAction.succeededAction,
            TerminologyActions.loadLabValueTypesAction.succeededAction,
            TerminologyActions.loadVentilationParameterTypesAction.succeededAction,
            TerminologyActions.loadVentilationModesAction.succeededAction,
            TerminologyActions.loadProcedureCategoriesAction.succeededAction,
            TerminologyActions.loadPrescriptionFrequenciesAction.succeededAction,
            TerminologyActions.loadMedicationsAction.succeededAction,
            TerminologyActions.loadMedicationCategoriesAction.succeededAction,
            TerminologyActions.loadMedicationGroupsAction.succeededAction,
            TerminologyActions.loadMedicationAdministrationMethodsAction.succeededAction,
            TerminologyActions.loadMedicationUnitsAction.succeededAction,
            TerminologyActions.loadMedicationSolutionsAction.succeededAction,
            TerminologyActions.loadOutputFactorsAction.succeededAction,
            TerminologyActions.loadPrescriptionFrequencyTimesAction.succeededAction,
            TerminologyActions.loadBasicCareProcedureTypesAction.succeededAction,
            TerminologyActions.loadPractitionerShiftsAction.succeededAction,
            TerminologyActions.loadCareCheckTypesAction.succeededAction,
            TerminologyActions.loadBloodAdministrationTypesAction.succeededAction,
            TerminologyActions.loadDischargeReasonsAction.succeededAction,
            TerminologyActions.loadDosageFormsAction.succeededAction,
            TerminologyActions.loadTaskListShiftFiltersAction.succeededAction,
            () => new Date(),
        ),
        on(TerminologyActions.invalidateCache, () => null),
    ),
};
/**
 * Terminology reducer
 *
 * @param reducer
 */
export const terminologyReducer = combineReducers(terminologyReducerMap);

/**
 * Terminology {@link MetaReducer}
 *
 * This meta reducer is used to set the loadAction.inProgress to false when the some terminology dictionary is loaded by unidentified code
 *
 * @todo when refactor to simple ngrx store, remove this unnecessary assignment
 * @param reducer
 */
export const terminologyMetaReducer: MetaReducer<TerminologyState, Action> = reducer => (state, action) => {
    if (action.type === setTerminologyLoadedSuccess.type) {
        const { terminologyName } = action as Action & { terminologyName: keyof TerminologyState };
        const terminologyNameState: TerminologyState[typeof terminologyName] = structuredClone(state[terminologyName]);
        if ('loadAction' in terminologyNameState) {
            terminologyNameState.loadAction.inProgress = false;
            terminologyNameState.loadAction.succeeded = true;
            terminologyNameState.loadAction.finished = true;
            return {
                ...state,
                [terminologyName]: terminologyNameState,
            };
        }
        return state;
    }
    return reducer(state, action);
};
