import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfectionStatus } from '@mona/models';
import { InfectionForm } from '../../models';

/**
 * Infection dialog
 */
@Component({
    selector: 'app-infection-dialog',
    templateUrl: './infection-dialog.component.html',
    styleUrls: ['./infection-dialog.component.scss'],
})
export class InfectionDialogComponent {
    /**
     * Infection form
     */
    infectionForm = new FormGroup<InfectionForm>({
        infectionName: new FormControl<string>('', Validators.required),
        infectionAnnotation: new FormControl<string>('', Validators.required),
        infectionStatus: new FormControl<string>('', Validators.required),
    });

    /**
     * Constructor
     *
     * @param dialogRef MatDialogRef<InfectionDialogComponent>
     * @param data dialog data
     */
    constructor(
        private dialogRef: MatDialogRef<InfectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            infection: InfectionStatus;
            isBedSideMode: boolean;
        },
    ) {
        dialogRef.addPanelClass('app-infection-dialog');

        if (data.infection.id) {
            this.infectionForm.patchValue(data.infection);
        }
    }

    /**
     * Submit change
     */
    submitChange(): void {
        this.infectionForm.markAsTouched();

        if (this.infectionForm.valid) {
            this.dialogRef.close({
                id: this.data.infection.id,
                infectionName: this.infectionForm.controls.infectionName.value,
                infectionAnnotation: this.infectionForm.controls.infectionAnnotation.value,
                infectionStatus: this.infectionForm.controls.infectionStatus.value,
            } as InfectionStatus);
        }
    }
}
