import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Valuables } from '@mona/models';
import { ChangeLogService } from '@mona/pdms/data-access-changelog';
import { ValuablesState } from '../entities';
import {
    ValuablesAction,
    selectValuablesAll,
    selectValuablesEntities,
    selectValuablesIsLoading,
} from '../state';

/**
 * ValuablesFacade
 */
@Injectable({ providedIn: 'root' })
export class ValuablesFacade {
    /** Get valuables */
    readonly valuables$: Observable<Valuables[]> = this.store.select(selectValuablesAll);

    /** valuables map */
    readonly valuablesMap$: Observable<Record<string, Valuables>> =
        this.store.select(selectValuablesEntities);

    /** is valuables data loading */
    readonly isLoading$ = this.store.select(selectValuablesIsLoading);

    /**
     * Constructor
     *
     * @param store store
     * @param changeLogService
     */
    constructor(private store: Store<ValuablesState>, private changeLogService: ChangeLogService) {}

    /** load valuables data */
    loadValuablesData(): void {
        this.store.dispatch(ValuablesAction.loadValuables());
    }

    /**
     * INFO: add comment
     * @param valuable
     */
    createValuable(valuable: Partial<Valuables>): void {
        this.changeLogService.createValuable(valuable);
    }

    /**
     * INFO: add comment
     * @param valuable
     */
    updateValuable(valuable: Partial<Valuables>): void {
        this.changeLogService.updateValuable(valuable);
    }

    /**
     * INFO: add comment
     * @param id
     */
    removeValuable(id: string): void {
        this.changeLogService.removeValuable(id);
    }

    /**
     * Clear valuables store
     */
    clearValuables(): void {
        this.store.dispatch(ValuablesAction.clearValuables());
    }
}
