import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ExternalResourcesState } from '../../entities/external-resources.state';
import * as fromReducer from '../reducers/external-resources.reducer';

export const selectExternalResourcesState = createFeatureSelector<ExternalResourcesState>(
    fromReducer.externalResourcesFeatureKey,
);

export const selectExternalResourcesLoading = createSelector(selectExternalResourcesState, state => state.isLoading);
export const selectExternalResourcesData = createSelector(selectExternalResourcesState, state => state.data);
export const selectSelectedExternalResource = createSelector(
    selectExternalResourcesState,
    state => state.activeResource,
);
