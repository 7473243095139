/* eslint-disable no-restricted-syntax */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { encounterDataFeatureKey, EncounterFeatureState, ValuablesState } from '../../entities';
import { valuablesAdapter } from "../reducers/valuables.reducer";

const selectEncounterState = createFeatureSelector(encounterDataFeatureKey);

export const selectValuablesState = createSelector(
    selectEncounterState,
    (state: EncounterFeatureState) => state.valuables,
);

export const {
    selectIds: selectValuablesIds,
    selectEntities: selectValuableEntities,
    selectAll: selectValuablesAll,
    selectTotal: selectValuablesTotal,
} = valuablesAdapter.getSelectors(selectValuablesState);

export const selectValuablesEntities = createSelector(
    selectValuablesState,
    (state: ValuablesState) => state.entities,
);

export const selectValuablesIsLoading = createSelector(
    selectValuablesState,
    (state: ValuablesState) => state.isLoading,
);
