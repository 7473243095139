import { createAction, props } from '@ngrx/store';
import { Encounter, PhysicalExamination } from '@mona/models';
import { AsyncAction, SucceededAction, FailedAction } from '@mona/store';

/**
 * Physical examination actions
 */
export class PhysicalExaminationAction {
    /**
     * Load physical examination action
     */
    static loadPhysicalExaminationAction: AsyncAction<
        {
            encounterId: EntityId<Encounter>;
        },
        PhysicalExamination
    > = {
        action: createAction(
            'ENCOUNTER:LOAD_PHYSICAL_EXAMINATION',
            props<{
                encounterId: EntityId<Encounter>;
            }>(),
        ),

        succeededAction: createAction(
            'ENCOUNTER:LOAD_PHYSICAL_EXAMINATION_SUCCEEDED',
            props<SucceededAction<PhysicalExamination>>(),
        ),

        failedAction: createAction('ENCOUNTER:LOAD_PHYSICAL_EXAMINATION_FAILED', props<FailedAction>()),

        clearAction: createAction('ENCOUNTER:LOAD_PHYSICAL_EXAMINATION_CLEAR'),
    };
}
