import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, skipWhile } from 'rxjs/operators';
import { ApiModels, getHttpHeaderByKey, HttpService, replaceUrlProtocol } from '@mona/api';
import {
    BasicCareProcedureType,
    BloodAdministrationType,
    CareCheckType,
    TerminologyDailyGoal,
    LabValueType,
    Medication,
    MedicationAdministrationMethod,
    MedicationCategory,
    MedicationDosageForm,
    MedicationGroup,
    MedicationSolution,
    MedicationUnit,
    OutputFactor,
    PractitionerShift,
    PrescriptionFrequency,
    PrescriptionFrequencyTime,
    PrescriptionNotGivenReason,
    ProcedureCategory,
    SearchDiagnosisResult,
    TaskListFilter,
    VentilationMode,
    VentilationParameterType,
    VitalSignType,
} from '@mona/models';
import { isNullOrUndefined } from '@mona/shared/utils';
import {
    transformApiBasicCareProcedureTypes,
    transformApiBloodAdministrations,
    transformApiCareCheckTypes,
    transformApiDailyGoals,
    transformApiDiagnosisSearchResults,
    transformApiDischargeReasons,
    transformApiDosageForms,
    transformApiLabValueTypes,
    transformApiMedication,
    transformApiMedicationAdministrationMethods,
    transformApiMedicationCategories,
    transformApiMedicationGroups,
    transformApiMedications,
    transformApiMedicationSolutions,
    transformApiMedicationUnits,
    transformApiOutputFactors,
    transformApiPractitionerShifts,
    transformApiPrescriptionFrequencies,
    transformApiPrescriptionFrequencyTimes,
    transformApiProcedureCategories,
    transformApiTaskListFilters,
    transformApiVentilationModes,
    transformApiVentilationParameterTypes,
    transformApiVitalSignTypes,
    transformToApiMedication,
    transformApiPrescriptionNotGivenReasons,
} from './transforms';

/**
 * API Service for Terminology
 */
@Injectable({
    providedIn: 'root',
})
export class TerminologyApi {
    apiBase = '/terminology/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Searches for a diagnosis name
     *
     * @param term Search term
     */
    searchDiagnosis(term: string): Observable<SearchDiagnosisResult[]> {
        return this.http
            .get<ApiModels.DiagnosisDB[]>(`${this.apiBase}diagnoses`, {
                params: {
                    name: term,
                },
            })
            .pipe(map(results => transformApiDiagnosisSearchResults(results)));
    }

    /**
     * Loads available vital sign types
     *
     * @param params
     */
    getVitalSignTypes(params: any = {}): Observable<VitalSignType[]> {
        return this.http
            .get<ApiModels.VitalSignType[]>(`${this.apiBase}vital-signs/`, { params })
            .pipe(map(results => transformApiVitalSignTypes(results)));
    }

    /**
     * Loads available lab value types
     *
     * @param params
     */
    getLabValueTypes(params: { name?: string; codes?: string[] } = {}): Observable<LabValueType[]> {
        params = HttpService.buildHttpParams(params) as any;
        (params as any).is_visible = true;
        return this.http
            .get<ApiModels.LabValueType[]>(`${this.apiBase}lab-values/`, {
                params,
            })
            .pipe(map(results => transformApiLabValueTypes(results)));
    }

    /**
     * Loads available ventilation parameter types
     *
     * @param params
     */
    getVentilationParameterTypes(params: any = {}): Observable<VentilationParameterType[]> {
        return this.http
            .get<ApiModels.TerminologyVentilationParameter[]>(`${this.apiBase}ventilation-parameters/`, { params })
            .pipe(map(results => transformApiVentilationParameterTypes(results)));
    }

    /**
     * Loads available ventilation modes
     *
     * @param params
     */
    getVentilationModes(params: any = {}): Observable<VentilationMode[]> {
        return this.http
            .get<ApiModels.VentilationMode[]>(`${this.apiBase}ventilation-modes/`, { params })
            .pipe(map(results => transformApiVentilationModes(results)));
    }

    /**
     * Loads available medication categories
     */
    getMedicationCategories(): Observable<MedicationCategory[]> {
        return this.http
            .get<ApiModels.MedicationCategory[]>(`${this.apiBase}medication-categories/`)
            .pipe(map(results => transformApiMedicationCategories(results)));
    }

    /**
     * Loads available medication groups
     */
    getMedicationGroups(): Observable<MedicationGroup[]> {
        return this.http
            .get<ApiModels.MedicationGroup[]>(`${this.apiBase}medication-groups/`)
            .pipe(map(results => transformApiMedicationGroups(results)));
    }

    /**
     * Loads available medication categories
     */
    getMedicationUnits(): Observable<MedicationUnit[]> {
        return this.http
            .get<ApiModels.MedicationUnit[]>(`${this.apiBase}medication-units/`)
            .pipe(map(results => transformApiMedicationUnits(results)));
    }

    /**
     * Loads available medication categories
     */
    getMedicationSolutions(): Observable<MedicationSolution[]> {
        return this.http
            .get<ApiModels.MedicationSolution[]>(`${this.apiBase}medication-solutions/`)
            .pipe(map(results => transformApiMedicationSolutions(results)));
    }

    /**
     * Loads available procedure categories
     */
    getProcedureCategories(): Observable<ProcedureCategory[]> {
        return this.http
            .get<ApiModels.ProcedureCategory[]>(`${this.apiBase}procedure-categories/`)
            .pipe(map(results => transformApiProcedureCategories(results)));
    }

    /**
     * Loads available prescription frequencies
     *
     * @param codes list of codes
     */
    getPrescriptionFrequencies(codes?: string[]): Observable<PrescriptionFrequency[]> {
        const params: {
            [param: string]: string | string[];
        } = {};

        if (codes?.length) {
            params.code = codes.reduce((typeAcc, type, index) => {
                typeAcc += index === 0 ? type : `,${type}`;
                return typeAcc;
            }, '');
        }

        return this.http
            .get<ApiModels.PrescriptionFrequency[]>(`${this.apiBase}prescription-frequencies/`, {
                params,
            })
            .pipe(map(results => transformApiPrescriptionFrequencies(results)));
    }

    /**
     * Loads available medication administration methods
     *
     * @param codes list of codes
     */
    getMedicationAdministrationMethods(codes?: string[]): Observable<MedicationAdministrationMethod[]> {
        const params: {
            [param: string]: string | string[];
        } = {};

        if (codes?.length) {
            params.code = codes.reduce((typeAcc, type, index) => {
                typeAcc += index === 0 ? type : `,${type}`;
                return typeAcc;
            }, '');
        }

        return this.http
            .get<ApiModels.AdministrationMethod[]>(`${this.apiBase}medication-administration-methods/`, {
                params,
            })
            .pipe(map(results => transformApiMedicationAdministrationMethods(results)));
    }

    /**
     * Get specific medication by id or absolute url
     *
     * @param url string
     */
    getMedicationByUrl(url: string): Observable<Medication> {
        return this.http.get<ApiModels.Medication>(url).pipe(
            map(apiMedication => transformApiMedication(apiMedication)),
            skipWhile(res => (res as unknown as HttpErrorResponse).error?.constructor?.name === 'ProgressEvent'),
            catchError((err, obs) => {
                if (err.error?.constructor?.name === 'ProgressEvent') {
                    return obs.source;
                }
                return of(null);
            }),
        );
    }

    /**
     * Create medication
     *
     * @param data Medication
     * @param createAs
     */
    createMedication(
        data: Medication,
        createAs: 'medications' | 'medication-groups' = 'medications',
    ): Observable<Medication> {
        return this.http
            .request<ApiModels.Medication>('POST', `${this.apiBase}${createAs}/`, {
                body: {
                    ...transformToApiMedication(data),
                    is_user_defined: true,
                },
                observe: 'response',
                responseType: 'json',
                reportProgress: false,
            })
            .pipe(
                map(({ headers, url }) => ({ headers, url })),
                exhaustMap(({ headers, url }) => {
                    const resourseUrl = replaceUrlProtocol(getHttpHeaderByKey(headers, 'location'), url);
                    return this.getMedicationByUrl(resourseUrl);
                }),
                catchError(() => of(null)),
            );
    }

    /**
     * Loads available medications
     *
     * @param name for fuzzy search
     * @param codes list of codes
     */
    getMedications(name?: string, codes?: string[]): Observable<Medication[]> {
        const params: {
            [param: string]: string | string[];
        } = {};

        if (name) {
            params.name = `${name}`;
        }

        if (codes?.length) {
            codes = codes.filter(c => !isNullOrUndefined(c));
            params.medicine_codes = codes.reduce((typeAcc, type, index) => {
                typeAcc += index === 0 ? type : `,${type}`;
                return typeAcc;
            }, '');
        }

        return this.http
            .get<ApiModels.Medication[]>(`${this.apiBase}medications/`, {
                params,
            })
            .pipe(map(results => transformApiMedications(results)));
    }

    /**
     * Search available medications OR groups
     *
     * @param name for fuzzy search
     * @param searchBy 'medications' | 'medications-group'
     */
    getMedicationsSuggestions(
        name: string,
        searchBy: 'medications' | 'medication-groups' = 'medications',
    ): Observable<Medication[]> {
        const params: {
            [param: string]: string | string[];
        } = { name: `${name}` };

        return this.http
            .get<ApiModels.Medication[]>(`${this.apiBase}${searchBy}/`, {
                params,
            })
            .pipe(map(results => transformApiMedications(results)));
    }

    /**
     * Search available medications by group_id or/and category code
     *
     * @param params for fuzzy search
     * @param params.name
     * @param params.group_id
     * @param params.category_code
     */
    searchMedicationsByParams({
        name,
        group_id,
        category_code,
    }: {
        // tslint:disable: completed-docs
        name?: string;
        group_id?: string;
        category_code?: string;
        // tslint:enable: completed-docs
    }): Observable<Medication[]> {
        const params = {};
        if (name) {
            Object.assign(params, { name });
        }
        if (group_id) {
            Object.assign(params, { group_id });
        }
        if (category_code) {
            Object.assign(params, { category_code });
        }
        return this.http
            .get<ApiModels.Medication[]>(`${this.apiBase}medications/`, {
                params,
            })
            .pipe(map(results => transformApiMedications(results)));
    }

    /**
     * Loads available output factors
     */
    getOutputFactors(): Observable<OutputFactor[]> {
        return this.http
            .get<ApiModels.OutputFactor[]>(`${this.apiBase}output-factors/`)
            .pipe(map(results => transformApiOutputFactors(results)));
    }

    /**
     * Loads available prescription frequency times
     */
    getPrescriptionFrequencyTimes(): Observable<PrescriptionFrequencyTime[]> {
        return this.http
            .get<ApiModels.FrequencyTime[]>(`${this.apiBase}prescription-frequency-times/`)
            .pipe(map(results => transformApiPrescriptionFrequencyTimes(results)));
    }

    /**
     * Loads basic care procedure types
     */
    getBasicCareProcedureTypes(): Observable<BasicCareProcedureType[]> {
        return this.http
            .get<ApiModels.BasicCareProcedure[]>(`${this.apiBase}basic-care-procedures/`)
            .pipe(map(results => transformApiBasicCareProcedureTypes(results)));
    }

    /**
     * Loads practitioner shifts
     */
    getPractitionerShifts(): Observable<PractitionerShift[]> {
        return this.http
            .get<ApiModels.PractitionerShift[]>(`${this.apiBase}practitioner-shifts/`)
            .pipe(map(results => transformApiPractitionerShifts(results)));
    }

    /**
     * Loads care check types
     *
     * @param params
     */
    getCareCheckTypes(params?: any): Observable<CareCheckType[]> {
        return this.http
            .get<ApiModels.CareCheckType[]>(`${this.apiBase}care-check-types/`, { params })
            .pipe(map(results => transformApiCareCheckTypes(results)));
    }

    /**
     * Loads blood administration types
     */
    getBloodAdministrationTypes(): Observable<BloodAdministrationType[]> {
        return this.http
            .get<ApiModels.BloodAdministration[]>(`${this.apiBase}blood-administrations/ `)
            .pipe(map(results => transformApiBloodAdministrations(results)));
    }

    /**
     * Loads discharge reasons list
     */
    getDischargeReasonsList(): Observable<any[]> {
        return this.http
            .get<any[]>('/terminology/discharge-reasons/')
            .pipe(map(results => transformApiDischargeReasons(results)));
    }

    /**
     * Loads dosage forms
     */
    getDosageForms(): Observable<MedicationDosageForm[]> {
        return this.http
            .get<ApiModels.DosageForm[]>('/terminology/dosage-forms/')
            .pipe(map(results => transformApiDosageForms(results)));
    }

    /**
     * Loads task list shift filters
     */
    getTaskListShiftFilters(): Observable<TaskListFilter[]> {
        return this.http
            .get<ApiModels.TaskListFilter[]>('/terminology/task-list-filters/')
            .pipe(map(transformApiTaskListFilters));
    }

    /**
     * Loads goals terminology
     */
    getDailyGoalsTerminology(): Observable<TerminologyDailyGoal[]> {
        return this.http
            .get<ApiModels.TerminologyDailyGoal[]>('/terminology/daily-goals/')
            .pipe(map(results => transformApiDailyGoals(results)));
    }

    /**
     * Loads prescription not given reasons
     */
    getPrescriptionNotGivenReason(): Observable<PrescriptionNotGivenReason[]> {
        return this.http
            .get<PrescriptionNotGivenReason[]>(`${this.apiBase}prescription-not-given-reasons/`)
            .pipe(
                map(prescriptionNotGivenReasons =>
                    transformApiPrescriptionNotGivenReasons(prescriptionNotGivenReasons),
                ),
            );
    }
}
