import { createAction, props } from '@ngrx/store';
import { Encounter, Anamnesis } from '@mona/models';
import { AsyncAction, SucceededAction, FailedAction } from '@mona/store';

/**
 * Anamnesis actions
 */
export class AnamnesisAction {
    /**
     * Load anamnesis action
     */
    static loadAnamnesisAction: AsyncAction<
        {
            encounterId: EntityId<Encounter>;
        },
        Anamnesis
    > = {
        action: createAction(
            'ENCOUNTER:LOAD_ANAMNESIS',
            props<{
                encounterId: EntityId<Encounter>;
            }>(),
        ),

        succeededAction: createAction('ENCOUNTER:LOAD_ANAMNESIS_SUCCEEDED', props<SucceededAction<Anamnesis>>()),

        failedAction: createAction('ENCOUNTER:LOAD_ANAMNESIS_FAILED', props<FailedAction>()),

        clearAction: createAction('ENCOUNTER:LOAD_ANAMNESIS_CLEAR'),
    };
}
