import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SurgeryPrescriptionForm } from '../../models';

/**
 * Surgery Prescription Form
 */
@Component({
    selector: 'app-surgery-prescription-form',
    templateUrl: './surgery-prescription-form.component.html',
    styleUrls: ['./surgery-prescription-form.component.scss'],
})
export class SurgeryPrescriptionFormComponent {
    /**
     * Form group
     */
    @Input() formGroup: FormGroup<SurgeryPrescriptionForm>;

    /**
     * Is bed side mode
     */
    @Input() isBedSideMode: boolean;

    /**
     * Is form active / visible
     */
    @Input() isActive: boolean;

    /**
     * Max textarea rows count size
     */
    @Input() textareaMaxRows = 40;

    /**
     * Keyboard enter clicked
     */
    @Output() keyboardEnterClicked: EventEmitter<void> = new EventEmitter();
}
