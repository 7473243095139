import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { DailyGoal } from '@mona/models';
import { transformApiDailyGoals } from './transforms';

/**
 * INFO: DailyGoalsApi
 */
@Injectable({ providedIn: 'root' })
export class DailyGoalsApi {
    private readonly apiBase = '/pdms/daily-goals';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Loads daily goals
     * @param encounter_id
     */
    getDailyGoals(encounter_id: string): Observable<DailyGoal[]> {
        return this.http
            .get<ApiModels.DailyGoal[]>(this.apiBase, {
                params: { encounter_id },
            })
            .pipe(map(apiDailyGoals => transformApiDailyGoals(apiDailyGoals)));
    }
}
