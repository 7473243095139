import { TypedAction } from '@ngrx/store/src/models';
import { SucceededAction } from '../models';

/**
 * Helper class for entity reducing
 */
export class EntityReducerHelper<E extends Entity> {
    /**
     * Constructor
     * @param initialState E
     */
    constructor(private initialState: E) {}

    /**
     * Add entity
     * @param state state
     * @param action action
     */
    reduceLoadEntitySucceededAction(state: E, action: SucceededAction<E> & TypedAction<any>): E {
        return action.payload;
    }

    /**
     * Clears entity
     * @param state state
     */
    reduceLoadEntityClearAction(state: E): E {
        return this.initialState;
    }
}
