/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable jsdoc/require-jsdoc */
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Valuables } from '@mona/models';

export namespace ValuablesAction {
    export const loadValuables = createAction('ENCOUNTER:LOAD_VALUABLES');
    export const loadValuablesSuccess = createAction(
        'ENCOUNTER:LOAD_VALUABLES_SUCCESS',
        props<{ data: Valuables[] }>(),
    );
    export const loadValuablesFailure = createAction('ENCOUNTER:LOAD_VALUABLES_FAILURE', props<{ error: any }>());

    export const updateValuables = createAction(
        'ENCOUNTER:UPDATE_VALUABLES',
        props<{ update: Update<Valuables>[] }>(),
    );

    export const upsertValuables = createAction(
        'ENCOUNTER:UPSERT_VALUABLES',
        props<{ data: Valuables[] }>(),
    );

    export const clearValuables = createAction('ENCOUNTER:CLEAR_VALUABLES');

    export const addValuablesChanges = createAction(
        'ENCOUNTER:ADD_CHANGES',
        props<{ toUpdateEntities: Valuables[]; toRemoveIds: string[] }>(),
    );
}
