import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Valuables } from '@mona/models';
import { ValuableForm} from '../../models';

/**
 * Valuable dialog
 */
@Component({
    selector: 'app-valuable-dialog',
    templateUrl: './valuable-dialog.component.html',
    styleUrls: ['./valuable-dialog.component.scss'],
})
export class ValuableDialogComponent {
    /**
     * valuable form
     */
    valuableForm = new FormGroup<ValuableForm>({
        valuableAmount: new FormControl<string>(null, Validators.required),
        valuableName: new FormControl<string>('', Validators.required),
    });

    /**
     * Constructor
     *
     * @param dialogRef MatDialogRef<ValuableDialogComponent>
     * @param data dialog data
     */
    constructor(
        private dialogRef: MatDialogRef<ValuableDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            valuable: Valuables;
            isBedSideMode: boolean;
        },
    ) {
        dialogRef.addPanelClass('app-valuable-dialog');

        if (data.valuable.id) {
            this.valuableForm.patchValue(data.valuable);
        }
    }

    /**
     * Submit change
     */
    submitChange(): void {
        this.valuableForm.markAsTouched();

        if (this.valuableForm.valid) {
            this.dialogRef.close({
                id: this.data.valuable.id,
                valuableAmount: this.valuableForm.controls.valuableAmount.value,
                valuableName: this.valuableForm.controls.valuableName.value,
            } as Valuables);
        }
    }
}
