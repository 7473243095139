<form
    *ngIf="formGroup"
    [formGroup]="formGroup"
    [isEnabled]="isBedSideMode && isActive"
    (ngSubmit)="keyboardEnterClicked($event)"
>
    <div class="app-vaccination-status-form__row">
        <mat-form-field
            class="app-input-field app-vaccination-status-form__field"
            appearance="fill"
            data-testid="ff-vaccinationDate"
        >
            <mat-label>{{ 'apps.admission.vaccination.vaccinationDate.textLabel' | translate }}</mat-label>
            <textarea
                [formControl]="formGroup.controls.vaccinationDate"
                required
                matInput
                [cdkAutosizeMaxRows]="textareaMaxRows"
                cdkAutosizeMinRows="2"
                cdkTextareaAutosize
                data-testid="ff-vaccinationDateTextarea"
            ></textarea>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.vaccinationDate"></ui-validation-message>
            </mat-error>
        </mat-form-field>

        <mat-form-field
            class="app-input-field app-vaccination-status-form__field"
            appearance="fill"
            data-testid="ff-vaccinationStatusText"
        >
            <mat-label>{{ 'apps.admission.vaccination.vaccinationAgainst.textLabel' | translate }}</mat-label>
            <textarea
                [formControl]="formGroup.controls.vaccinationAgainst"
                required
                matInput
                [cdkAutosizeMaxRows]="textareaMaxRows"
                cdkAutosizeMinRows="2"
                cdkTextareaAutosize
                data-testid="ff-vaccinationAgainstTextarea"
            ></textarea>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.vaccinationAgainst"></ui-validation-message>
            </mat-error>
        </mat-form-field>

        <mat-form-field
            class="app-input-field app-vaccination-status-form__field"
            appearance="fill"
            data-testid="ff-verificationManufacturer"
        >
            <mat-label>{{ 'apps.admission.vaccination.verificationManufacturer.textLabel' | translate }}</mat-label>
            <textarea
                [formControl]="formGroup.controls.verificationManufacturer"
                required
                matInput
                [cdkAutosizeMaxRows]="textareaMaxRows"
                cdkAutosizeMinRows="2"
                cdkTextareaAutosize
                data-testid="ff-verificationManufacturerTextarea"
            ></textarea>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.verificationManufacturer"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>
    <ng-content></ng-content>
</form>
