import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VaccinationForm } from '../../models';

/**
 * Vaccination Status Form
 */
@Component({
    selector: 'app-vaccination-form',
    templateUrl: './vaccination-form.component.html',
    styleUrls: ['./vaccination-form.component.scss'],
})
export class VaccinationFormComponent {
    /**
     * Form group
     */
    @Input() formGroup: FormGroup<VaccinationForm>;

    /**
     * Is bed side mode
     */
    @Input() isBedSideMode: boolean;

    /**
     * Is form active / visible
     */
    @Input() isActive: boolean;

    /**
     * Max textarea rows count size
     */
    @Input() textareaMaxRows = 40;

    /**
     * Keyboard enter clicked
     */
    @Output() keyboardEnterClicked: EventEmitter<void> = new EventEmitter();
}
