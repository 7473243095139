import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { makeDefaultAsyncActionEffect } from '@mona/store';
import { AnamnesesApi } from '../../infrastructure';
import { AnamnesisAction } from '../actions';

/**
 * Anamnesis effects
 */
@Injectable()
export class AnamnesisEffects {
    /* Effect Declarations */

    /**
     * Load anamnesis effect
     */

    loadAnamnesis$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AnamnesisAction.loadAnamnesisAction.action),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.anamnesesApi.getAnamneses(action.encounterId).pipe(map(anamnesis => anamnesis[0])),
                    AnamnesisAction.loadAnamnesisAction,
                ),
            ),
        ),
    );

    /**
     * Constructor
     *
     * @param actions$ Actions
     * @param anamnesesApi AnamnesesApi
     */
    constructor(private actions$: Actions, private anamnesesApi: AnamnesesApi) {}
}
